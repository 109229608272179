@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .active {
    @apply bg-slate-100 text-black font-bold;
  }
  .cover {
    @apply backdrop-blur-sm absolute !z-[1201] bg-cover  h-screen w-screen flex justify-center items-center left-0 right-0 top-0 bottom-0;
  }
  .modal {
    @apply flex flex-col shadow-xl relative rounded-lg px-10 py-8 max-w-[450px] overflow-hidden w-[50%]  bg-[#0e1219] gap-y-3  pt-[85px];
  }
  .modal-row {
    @apply flex gap-y-1 items-center flex-wrap   gap-x-[20px] justify-between;
  }
  .modal-row-header {
    @apply text-lg font-semibold  w-[70px];
  }
  .modal-row-unit {
    @apply min-w-[36px] text-sm;
  }
}
* {
  box-sizing: border-box;
}
*:disabled {
  opacity: 0.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --nav-h: 80px;
  --layout-h: 100vh;
  --main-bg-color: #0e1219;
  --main-text-color: #f5f5f7;
  --content-pd: 56px;

  user-select: none;
  -webkit-user-select: none;
}

.scrollbar-hide {
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Webkit browsers */
}

.layout {
  margin-top: var(--nav-h);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--content-pd);
  position: relative;
}
.loading {
  position: relative;
  top: -60px;
  z-index: 99;
  width: 100vw;
  height: 100%;
}
.flex {
  display: flex;
}
.between {
  justify-content: space-between;
}
.head-box {
  flex-direction: row !important;
  row-gap: 15px;
  justify-content: space-between;
}

.cover > div {
  min-width: 300px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin-left: 10px;
}
@media (max-width: 768px) {
  :root {
    --nav-h: 80px;
    --layout-h: calc(100vh - var(--nav-h));
    --main-bg-color: #0e1219;
    --main-text-color: #f5f5f7;
    --content-pd: 20px;

    user-select: none;
    -webkit-user-select: none;
  }
}
@media (max-width: 768px) {
  * {
    max-width: 100vw;
  }
  .main-box {
    padding-top: 0px !important;
  }
  /* .overviewText{
    font-size: 1.5rem !important;
  } */
  .head-box {
    flex-direction: column !important;
    row-gap: 15px;
    justify-content: center;
    align-items: center;
  }
  .selectDate {
    margin-left: 5px;
  }
}
.simplebar-placeholder {
  display: none !important;
}
