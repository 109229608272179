body,
#root {
  /* background-color: #0e1219; */
  /* temporary solution */
  background-color: #0d131f; 
  color: var(--main-text-color);
  height: var(--layout-h);
}
.main-bg {
  animation: backgroundAnimation 8s infinite ease-in-out forwards;
  width: 55%;
  height: 80%;
  background: radial-gradient(
      circle,
      rgba(238, 174, 202, 0.36086956521739133) 0%,
      rgba(148, 187, 233, 0.3978260869565218) 75%
    );
    filter: blur(100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
@keyframes backgroundAnimation {
  0% {
  }
  50% {
    width: 85%;
    

  }
  100% {
    

  }
}
.main-box{
    z-index: 2;
}

@media screen {
  
}