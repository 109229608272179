.navbar{
    width: 100%;
    height: var(--nav-h);
    /* background-color: #131820f7; */
    display: flex;
    padding: 15px var(--content-pd) ;
    column-gap: 5px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
}
.nav-btn{
    height: 50px;
    background-color: #383bec;
    background-image:linear-gradient(-45deg,#ee7752,#e73c7e);
    border: none;
    border-radius: 5px;
    color: aliceblue;
    font-size: medium;
    font-weight: 700;
    padding: 10px;

}